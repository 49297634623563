<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify" v-model="search">
            </v-text-field>
            <v-data-table :headers="headers" :hide-default-footer="true" :items="desserts" sort-by="phone" :items-per-page="15" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> السيرة </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="890px">
                            <!-- <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                    v-on="on">
                                    {{ $t("add_new") }}
                                </v-btn>
                            </template> -->
                            <v-stepper v-model="e1">
                                <!-- <v-toolbar dark color="primary lighten-1 mb-5" style="height:40px">
                                                        <v-toolbar-title>
                                                            <h3 style="color:#fff"> {{formTitle}}</h3>
                                                        </v-toolbar-title>
                                                        <v-spacer />
                                                        <v-btn @click="close()" icon>
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-toolbar> -->
                                <!-- <v-stepper-header>
                                    <v-stepper-step editable :complete="e1 > 1" v-if="editedIndex > -1" step="1"
                                        style="    color: #fff;">
                                        {{formTitle}}
                                    </v-stepper-step>

                                    <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                                        {{formTitle}}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step editable v-if="editedIndex > -1" :complete="e1 > 2" step="2">
                                        {{ $t("add_pic") }}
                                    </v-stepper-step>


                                    <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                                        {{ $t("add_pic") }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>


                                </v-stepper-header> -->

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-card class="mb-12" flat>

                                            <v-form ref="form" v-model="valid">

                                                <!-- <v-toolbar dark color="primary lighten-1 mb-5">
                                                        <v-toolbar-title>
                                                            <h3 style="color:#fff"> {{formTitle}}</h3>
                                                        </v-toolbar-title>
                                                        <v-spacer />
                                                        <v-btn @click="close()" icon>
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-toolbar> -->

                                                <v-card-text>
                                                    <v-container>

                                                        <v-row>

                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-select v-model="editedItem.articles_types_id" :label="$t('cats')" :items="cats" outlined item-text="name" item-value="id"></v-select>
                                                            </v-col> -->


                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.title.ar" :rules="messageRules"  required :label="$t('datatable.title')" outlined>
                                                                </v-text-field>
                                                            </v-col> -->




                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.title.en" :rules="messageRules"  required
                                                                    :label="$t('datatable.title')+' '+$t('en')"
                                                                    outlined>
                                                                </v-text-field>
                                                            </v-col> -->

                                                            



                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.text.ar" :rules="messageRules"  required :label="$t('datatable.text')" outlined>
                                                                </v-textarea>
                                                            </v-col>



                                                            <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.text.en" :rules="messageRules"  required :label="$t('datatable.text')+' '+$t('en')" outlined>
                                                                </v-textarea>
                                                            </v-col> -->
                     




                                                            <v-col class="py-0" cols="6" sm="12" md="6">
                                                                <v-text-field :label="$t('Drawer.choose_img')"
                                                                    @click='pickImg' v-model='imageName'
                                                                    prepend-icon='fas fa-paperclip'>
                                                                </v-text-field>
                                                                <input type="file" style="display: none" ref="image"
                                                                    accept="image/*" @change="onFilePicked">

                                                            </v-col>



                                                            <v-col class="py-0" cols="6" sm="6" md="6">
                                                                <v-img :src="imageUrl" height="150"
                                                                    :lazy-src="thumb_small" v-if="imageUrl" />
                                                            </v-col>


<!-- 
                                                            <v-col class="py-0" cols="6" sm="6" md="6"
                                                                v-if="editedIndex == -1">

                                                                <v-text-field label="أرفاق فيديؤ " @click='pickFile'
                                                                    v-model='file_name' id="file"
                                                                    prepend-icon='fas fa-paperclip'>
                                                                </v-text-field>


                                                                <input type="file" style="display: none" ref="file"
                                                                    id="file" accept="application/application/,.mp4,"
                                                                    v-on:change="onPickfile">

                                                            

                                                            </v-col> -->






<!-- 
                                                            <div v-if="show_loading">
                                                                <p>يتم رفع الفديؤ</p>
                                                                <v-progress-circular indeterminate color="#f77d1f"
                                                                    :size="150">
                                                                </v-progress-circular>
                                                            </div> -->




                                                        </v-row>

                                                    </v-container>
                                                </v-card-text>



                                            </v-form>

                                        </v-card>

                                        <v-btn color="primary" :loading="loadSave" @click="save()">
                                            {{$t("save")}}
                                        </v-btn>

                                        <v-btn text @click="close()">
                                            {{$t("close")}}
                                        </v-btn>
                                    </v-stepper-content>
<!-- 
                                    <v-stepper-content step="2">
                                        <UploudeImages :art="editedItem" :images="editedItem.images" />

                                        <v-btn text color="red" @click="close()">
                                            {{$t("close")}}
                                        </v-btn>
                                    </v-stepper-content> -->
                                </v-stepper-items>
                            </v-stepper>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">





                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn text @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                               {{ $t("edit") }}</v-btn>


                        </template>
                        <span>{{ $t("edit") }} </span>
                    </v-tooltip>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                {{$t('Delete')}}</v-btn>

                        </template>
                        <span>{{$t('Delete')}}</span>
                    </v-tooltip> -->



                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>

<!-- 
        <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination> -->


    </div>
</template>


<style>
    /* .v-stepper__label
{
    color: #fff !important;
} */
</style>
<script>
    //UploudeImages
    import Axios from "axios";

    export default {
        data() {
            return {
                desserts: [

                ],
                cats: [],
                dialog: false,
                loadSave: false,
                file_name: null,
                e1: 1,
        page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
                imagesUrl: '',
                imageName: '',
                show_loading: false,
                imageUrl: '',
                img_old: '',
                imageFile: '',

                imagesName: ' ',
                editedIndex: -1,
                editedItem: {
                    images:[],
                    image: {
                        image_name: ''
                    },
                    id: "",
                    title: {
                        ar: "",
                        en: ""
                    },
                    text: {
                        ar: "",
                        en: ""
                    },
                   
                    articles_types_id: 1

                },
                items: [

                ],
                x:this.$i18n.locale,
                headers:[],
                right: null,
                vvv: {},
                messageRules:[
                v => !!v || this.$t("valMessage")
                ],
            }
        },
 watch:{
            '$i18n.locale': function(newVal, oldVal) {
                oldVal
            this.x=newVal;
            this.get();  
  }, selected: 'search by sub_cat_id', },
        methods: {
            get(){
                return this.headers= [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },
                    {
                        text: 'الصفحة',
                        align: "start",
                        value: "page"
                    },
                    {
                        text: this.$t('datatable.text'),
                        align: "start",
                        value: this.x == 'en' ? "text.en" : "text.ar"
                    },
                                       {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },
        validate () {
    
        },
            editItem(item) {
                
                this.e1 = 1;
                this.editedIndex = this.desserts.indexOf(item);
                this.$store.dispatch("art_uploudess", item);
                this.editedItem = Object.assign({}, item);
                
                this.dialog = true;
                this.img_old = ''
                this.imageName = ''
                this.imageFile = null
                this.imageUrl = ''
                

               this.img_old = this.editedItem.img;
                this.imageUrl = this.Url + '/img/small/' + this.img_old;
            },

            pickFile() {
                this.$refs.file.click()
            },

            onPickfile(e) {

                this.file = this.$refs.file.files[0];
                let file = e.target.files;
                this.file = file[0]
                this.file_name = file[0].name
            },





            close() {
               
                this.e1 = 1;
     this.loadSave=false;
                this.img_old = ''
                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
                 this.dialog = false;
                // this.editedItem ={};
                this.editedItem =   {
                    images:[],
                    image: {
                        image_name: ''
                    },
                    id: "",
                    title: {
                        ar: "",
                        en: ""
                    },
                    text: {
                        ar: "",
                        en: ""
                    },
                   
                    articles_types_id: 1

                }


                this.$store.dispatch("art_uploudess", this.vvv);
                this.$store.dispatch("art_uploudess", this.vvv);
                // this.$nextTick(() => {
                //     this.editedItem = Object.assign({}, this.defaultItem);
                //     this.editedIndex = -1;
                // });
            },
            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
            initialize() {
                this.loading = true;
                Axios.get("/seera", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        } 
                    })
                    .then(res => {
                        this.loading = false;
                        this.desserts = res.data.data;
                        
                        this.last_page = res.data.last_page;
                     this.pageCount = res.data.last_page;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            pickImg() {
                this.$refs.image.click()
            },


            pickFiles() {
                this.$refs.images.click()

            },


            onFilePicked(e) {

                const files = e.target.files

                if (files[0] !== undefined) {

                    this.imageName = files[0].name

                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0]


                    })
                } else {
                    // this.imageName = ''
                    // this.imageFile = ''
                    // this.imageUrl = ''


                }
            },


            deleteItem(item) {
                item
                // Swal.fire({
                //     title: this.$t('sure_process'),
                //     text: "",
                //     icon: "warning",
                //     showCancelButton: true,
                //     confirmButtonColor: "#3085d6",
                //     cancelButtonColor: "#d33",
                //     confirmButtonText: this.$t('yes'),
                //     cancelButtonText: this.$t('no'),
                // }).then(result => {
                //     if (result.value) {
                //         Axios.delete("articles/" + item.id, {
                //                 headers: {
                //                     "Content-Type": "application/json",
                //                     Accept: "application/json",
                //                     Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                //                 }
                //             })
                //             .then(() => {
                //                 this.initialize();
                //                 this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");

                //             })
                //             .catch(() => {
                //                 this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                //             });
                //     }
                // });
            },
            getCats() {

                // Axios.get("/article_types", {
                //         headers: {
                //             "Content-Type": "application/json",
                //             Accept: "application/json",

                            
                //             Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                //         }
                //     })
                //     .then(res => {
                //         this.loading = false;
                //         this.cats = res.data;
                //     })
                //     .catch(() => {
                //         this.loading = false;
                //     });

            },
            save() {
             if(this.$refs.form.validate()){

                // console.log(this.editedItem);

                if (this.imageFile == '') {
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                } else {
                    this.loadSave = true;
                    if (this.editedIndex > -1) {

                        var update = {
                            translations: [{
                                    text:this.editedItem.text.ar,
                                },
                                {
                                    text: this.editedItem.text.en,
                                }
                            ],
                           

                        }
                        this.axios
                            .put("/seera/" + this.editedItem.id,update, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },
                            })
                            .then(() => {

                                if (this.imageFile != null) {
                                    let formData = new FormData();
                                    const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                    formData.append('photo', this.imageFile);
                                    Axios.post('/seera_update_img/' + this.editedItem.id,
                                        formData, {
                                            headers: {
                                                Authorizations: AuthStr,
                                                'Content-Type': 'multipart/form-data',
                                            }
                                        }
                                    )
                                    this.img_old = ''
                                    this.imageName = ''
                                    this.imageFile = ''
                                    this.imageUrl = ''
                                }
                                this.initialize();
                                this.close();

                                this.$swal.fire({
                                    title: "تم تعديل ",
                                    text: "",
                                    icon: "success",
                                    confirmButtonText: "اغلاق",
                                });
                            })
                            .catch(() => {
                                this.loadSave = false;
                                this.$swal.fire({
                                    title: "تاكد من ملى المعلومات",
                                    text: "",
                                    icon: "error",
                                    confirmButtonText: "اغلاق",
                                });
                            });
                    } else {

                        //   var savedate = {
                        //     articles_types_id: this.editedItem.articles_types_id,
                        //     translations: [{
                        //             title:this.editedItem.title.ar,
                        //             text:this.editedItem.text.ar,   },
                        //         {
                        //             title: this.editedItem.title.en,
                        //             text: this.editedItem.text.en,
                        //         }
                        //     ],
                           

                        // }

                        // console.log(savedate);

                        // this.axios
                        //     .post("seera", savedate, {
                        //         headers: {
                        //             "Content-Type": "application/json",
                        //             Accept: "application/json",
                        //             Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                        //         },
                        //     })
                        //     .then((res) => {
                        //         this.loadSave = false;
                        //     this.initialize();
                        //         this.editedItem = res.data;
                        //         this.editedIndex = -1;
                        //         // console.log(res);
                        //         if (this.imageFile != null) {
                        //             let formData = new FormData();
                        //             const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                        //             formData.append('photo', this.imageFile);
                        //             Axios.post('seera_uploude_img/' + this.editedItem.id,
                        //                     formData, {
                        //                         headers: {
                        //                             Authorizations: AuthStr,
                        //                             'Content-Type': 'multipart/form-data',
                        //                         }
                        //                     }
                        //                 )
                        //                 .then(response => {
                        //                     response

                        //                     this.e1 = 2
                        //                     this.loadSave = false;
                        //                     this.img_old = ''
                        //                     this.imageName = ''
                        //                     this.imageFile=''
                        //                     this.$swal.fire({
                        //                         title: 'تم الرفع بنجاح',
                        //                         text: "",
                        //                         icon: "success",
                        //                         confirmButtonText: this.$t('close'),
                        //                     });
                        //                     this.close();
                        //                     // }
                        //                 })
                        //             // this.img_old = ''
                        //             this.imageName = ''
                        //             // this.initialize();
                        //         }
                        //     })
                        //     .catch((err) => {
                        //         err
                        //         /// this.loadSave = false;
                        //     });
                    }
                }}

            },

        },
      
        components: {
            // UploudeImages: () => import("../../components/core/UploudeImages.vue"),

        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');

            },
            selected: function () {
        return this.getMoreitems();
      }
        },

        created() {
            this.initialize();
            this.get();
            this.getCats();

        },

    }
</script>